import { mapGetters } from 'vuex';
const { get } = require('../../helpers/cookie');

export default {
	name: 'AdvantagesPosting',
	data() {
		return {
			title: this.langId === 4 ? 'AUTO.RIA - Найкраще місце для продажу авто' : 'AUTO.RIA - Лучшее место для продажи авто',
			description: this.langId === 4 ? 'Сайт AUTO.RIA лідер у продажу авто в Україні. Проста публікація, прозора система просування і багато іншого!' : 'Сайт AUTO.RIA лидер в продаже авто в Украине. Простая публикация, прозрачная система продвижения и многое другое!',
			contentType: -1,
			sticky: false
		};
	},
	components: {
		'deployed': () => import('./Deployed/index.vue'),
		'laconic': () => import('./Laconic/index.vue')
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			langPrefix: 'lang/prefix',
			userData: 'Common/userData'
		}),
	},
	methods: {
		showContent() {
			const cookie = get('test_new_features');
			const type = Number(this.$route.query.full);
			if (!isNaN(type) && [0, 1].includes(type)) {
				this.contentType = type;
			} else {
				this.contentType = Number(Number(cookie) > 500);
			}
		},
		removeStickyFromBtn() {
			const IOW = require('../../helpers/IntersectionObserver_screen__01');
			IOW.add(this.$refs.btn, (entry = {}) => {
				if (entry.isIntersecting) {
					this.sticky = false;
				} else {
					this.sticky = true;
				}
			});
		},
		slonikLogs(action) {
			this._slonik({
				event_id: action ? 771 : 770,
				owner_id: this.userData.userId,
				screentype: this.isDesktop ? 1 : (this.isMobile ? 2 : 3),
				click_action: action,
				previous_url: action ? undefined : document.referrer,
				a_b_test_nameid: this.contentType,
			});
		},
	},
	i18n: require('./i18n').default,
	mounted() {
		this.showContent();
		this.removeStickyFromBtn();
	},
	watch: {
		userData(data) {
			if (data.webClientId) {
				this.slonikLogs();
			}
		}
	},
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{property: 'og:title', content: this.title},
				{name: 'description', content: this.description},
				{property: 'og:description', content: this.description},
			],
			link: [
				{ rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/best-place' },
				{ rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/best-place' },
				{ rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/best-place' },
				{ rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/best-place' },
				{ rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/best-place' }
			]
		};
	}
};
