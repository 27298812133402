import {mapGetters} from 'vuex';

export default {
	name: 'Deployed',
	i18n: require('../i18n').default,
	props: ['sticky'],
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
		})
	},
};
