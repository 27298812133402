const translate = {
	messages: {
		ru: {
			'краще місце для продажу': 'лучшее место для продажи',
			'Публікуйте безкоштовно': 'Публикуйте бесплатно',
			'хв': 'мин',
			'Продавайте швидко та вигідно': 'Продавайте быстро и выгодно',
			'Користуйтесь прозорою': 'Пользуйтесь прозрачной',
			'системою вигод': 'системой выгод',
			'Опублікувати': 'Опубликовать',
			'Перші в пошуку': 'Первые в поиске',
			'з': 'с',
			'Перевірений': 'Проверенный',
			'Більше уваги та довіри отримують пропозиції з': 'Больше внимания и доверия получают предложения с',
			'перевіреною за VINом історією': 'проверенной за VINом историей',
			'зафіксовані пробіги, ДТП, розшуки, тощо': 'зафиксированы пробеги, ДТП, поиски и т.д',
			'продавців вказують VIN і продають швидше': 'продавцов указывают VIN и продают быстрее',
			'Вищі місця з рівнями': 'Высшие места с уровнями',
			'у': 'в',
			'пошуку': 'поиска',
			'більш помітні серед': 'более заметны среди',
			'пропозицій': 'предложений',
			'Вони отримують удвічі більше переглядів та дзвінків, а продаються втричі швидше': 'Они получают вдвое больше просмотров и звонков, а продаются втрое быстрее',
			'Перевірки авто': 'Проверки авто',
			'Технічна, юридична та по': 'Техническая, юридическая и по',
			'перевірки': 'проверки',
			'які демонструють справжній стан авто та історію його експлуатації': 'которые демонстрируют истинное положение авто и историю его эксплуатации',
			'Система вигідних публікацій': 'Система выгодных публикаций',
			'Можливість для постійних клієнтів': 'Возможность для постоянных клиентов',
			'заощаджувати до': 'экономить до',
			'на розміщенні своїх пропозицій': 'на размещении своих предложений',
			'Відеоповідомлення': 'Видеосообщения',
			'Відео у пропозиції': 'Видео в предложении',
			'розкриває переваги краще, ніж десятки фото': 'раскрывает преимущества лучше, чем десятки фото',
			'В додатку': 'В приложении',
			'знімати відео зручно, а монтувати легко': 'снимать видео удобно, а монтировать легко',
			'Аудіо та відеодзвінки': 'Аудио и видеозвонки',
			'Показуйте авто': 'Показывайте авто',
			'покупцям із інших міст та спілкуйтесь із ними навіть': 'покупателям с других городов и общайтесь с ними даже',
			'покриття': 'покрытия',
			'мобільного оператора чи в роумінгу': 'мобильного оператора в роуминге',
			'Все, щоб ви продали легко та вигідно': 'Все, чтобы вы продали легко и выгодно',
			'перші в пошуку': 'первые в поиске',
			'й отримують': 'и получают',
			'удвічі більше дзвінків': 'вдвое больше звонков',
			'Рівні': 'Уровни',
			'піднімають вашу пропозицію і пришвидшують продаж на': 'поднимают ваше предложение и ускоряют продажу на',
			'днів': 'дней',
			'Ряд перевірок': 'Ряд проверок',
			'для демонстрації гарного стану та історії': 'для демонстрации хорошего состояния и истории',
			'Прозора система': 'Прозрачная система',
			'вигідних публікацій для регулярних продажів': 'выгодных публикаций для регулярных продаж',
			'Ще більше можливостей для': 'Еще больше возможностей для',
			'публікації': 'публикации',
			'та': 'и',
			'звязку': 'связи',
			'в популярному додатку': 'в популярном приложении',
			'Середній час продажу на': 'Среднее время продажи на',
			'Можна й швидше': 'Можно и быстрее',
		},
		uk: {
			'краще місце для продажу': 'краще місце для продажу',
			'Публікуйте безкоштовно': 'Публікуйте безкоштовно',
			'хв': 'хв',
			'Продавайте швидко та вигідно': 'Продавайте швидко та вигідно',
			'Користуйтесь прозорою': 'Користуйтесь прозорою',
			'системою вигод': 'системою вигод',
			'Опублікувати': 'Опублікувати',
			'Перші в пошуку': 'Перші в пошуку',
			'з': 'з',
			'Перевірений': 'Перевірений',
			'Більше уваги та довіри отримують пропозиції з': 'Більше уваги та довіри отримують пропозиції з',
			'перевіреною за VINом історією': 'перевіреною за VINом історією',
			'зафіксовані пробіги, ДТП, розшуки, тощо': 'зафіксовані пробіги, ДТП, розшуки, тощо',
			'продавців вказують VIN і продають швидше': 'продавців вказують VIN і продають швидше',
			'Вищі місця з рівнями': 'Вищі місця з рівнями',
			'у': 'у',
			'пошуку': 'пошуку',
			'більш помітні серед': 'більш помітні серед',
			'пропозицій': 'пропозицій',
			'Вони отримують удвічі більше переглядів та дзвінків, а продаються втричі швидше': 'Вони отримують удвічі більше переглядів та дзвінків, а продаються втричі швидше',
			'Перевірки авто': 'Перевірки авто',
			'Технічна, юридична та по': 'Технічна, юридична та по',
			'перевірки': 'перевірки',
			'які демонструють справжній стан авто та історію його експлуатації': 'які демонструють справжній стан авто та історію його експлуатації',
			'Система вигідних публікацій': 'Система вигідних публікацій',
			'Можливість для постійних клієнтів': 'Можливість для постійних клієнтів',
			'заощаджувати до': 'заощаджувати до',
			'на розміщенні своїх пропозицій': 'на розміщенні своїх пропозицій',
			'Відеоповідомлення': 'Відеоповідомлення',
			'Відео у пропозиції': 'Відео у пропозиції',
			'розкриває переваги краще, ніж десятки фото': 'розкриває переваги краще, ніж десятки фото',
			'В додатку': 'В додатку',
			'знімати відео зручно, а монтувати легко': 'знімати відео зручно, а монтувати легко',
			'Аудіо та відеодзвінки': 'Аудіо та відеодзвінки',
			'Показуйте авто': 'Показуйте авто',
			'покупцям із інших міст та спілкуйтесь із ними навіть': 'покупцям із інших міст та спілкуйтесь із ними навіть',
			'покриття': 'покриття',
			'мобільного оператора чи в роумінгу': 'мобільного оператора чи в роумінгу',
			'Все, щоб ви продали легко та вигідно': 'Все, щоб ви продали легко та вигідно',
			'перші в пошуку': 'перші в пошуку',
			'й отримують': 'й отримують',
			'удвічі більше дзвінків': 'удвічі більше дзвінків',
			'Рівні': 'Рівні',
			'піднімають вашу пропозицію і пришвидшують продаж на': 'піднімають вашу пропозицію і пришвидшують продаж на',
			'днів': 'днів',
			'Ряд перевірок': 'Ряд перевірок',
			'для демонстрації гарного стану та історії': 'для демонстрації гарного стану та історії',
			'Прозора система': 'Прозора система',
			'вигідних публікацій для регулярних продажів': 'вигідних публікацій для регулярних продажів',
			'Ще більше можливостей для': 'Ще більше можливостей для',
			'публікації': 'публікації',
			'та': 'та',
			'звязку': 'зв\'язку',
			'в популярному додатку': 'в популярному додатку',
			'Середній час продажу на': 'Середній час продажу на',
			'Можна й швидше': 'Можна й швидше',
		}
	}
};
export default translate;
